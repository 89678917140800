<template lang="">
  <div>
    <div id="pagebody" class="siteStyle pageStyle container-fluid    pagebody_nav pagebody_nav_1"
      style="padding-bottom: 0px;">
      <div id="BodyMainZoneContainer" class="BodyMainZoneContainer">
        <div id="HeaderZone" ismodulecontainer="true"
          class="HeaderContainer HeaderZoneContainer ZoneContainer HeaderZone ModuleContainer clearfix ">
        </div>

        <div id="BodyMain1Zone" ismodulecontainer="true"
          class="BodyContainer BodyZoneContainer ZoneContainer ModuleContainer BodyMain1Zone clearfix ">


          <!--轮播图-PC-->
          <div class="PC_min">
            <div class=" ModuleItem  StaticModule  mhidden-xs" wo="1358" id="module_62412474">

              <div class="ModuleSlideV2Giant layout-103 layout-color-black module_62412474 clearfix">

                <div class="BodyCenter BodyCenter62412474 clearfix">

                  <div
                    class="f-slide slider-layout-box slider-layout-103 module-slide-container slick-initialized slick-slider slick-dotted"
                    is-exist-module="false"><button type="button" class="f-prev slick-prev slick-arrow"
                      style="display: block;padding-top: 9px;"><svg t="1731475915137" class="icon"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2280" width="16"
                        height="16">
                        <path
                          d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z"
                          fill="#ffffff" p-id="2281"></path>
                      </svg>
                      <!-- <div><img src="@/assets/img/4e764448fb1612bc987cb873ee9f6b17.jpg">
                      </div> -->
                    </button>
                    <div class="slick-list draggable" v-if="BannerList.length>0">
                      <swiper :options="homeSwiper" ref="mySwiper1" @slideChange="onSlideChange('mySwiper1')"
                        class="slick-track">
                        <swiper-slide v-for="(item, index) in BannerList" :key="index">
                          <div>
                            <a href="javascript:void(0)">
                              <div class="slide-box nextScalePage">
                                <img class="swiperImg" :src="item.picture" alt="伟企集团，一站式数字服务平台" title="伟企集团，一站式数字服务平台">
                              </div>
                            </a>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                    <button type="button" class="f-next slick-next slick-arrow"
                      style="display: block; padding-top: 9px;"><svg t="1731474861806" class="icon"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8651" width="16"
                        height="16">
                        <path
                          d="M761.055557 532.128047c0.512619-0.992555 1.343475-1.823411 1.792447-2.848649 8.800538-18.304636 5.919204-40.703346-9.664077-55.424808L399.935923 139.743798c-19.264507-18.208305-49.631179-17.344765-67.872168 1.888778-18.208305 19.264507-17.375729 49.631179 1.888778 67.872168l316.960409 299.839269L335.199677 813.631716c-19.071845 18.399247-19.648112 48.767639-1.247144 67.872168 9.407768 9.791372 21.984142 14.688778 34.560516 14.688778 12.000108 0 24.000215-4.479398 33.311652-13.439914l350.048434-337.375729c0.672598-0.672598 0.927187-1.599785 1.599785-2.303346 0.512619-0.479935 1.056202-0.832576 1.567101-1.343475C757.759656 538.879828 759.199462 535.391265 761.055557 532.128047z"
                          fill="#ffffff" p-id="8652"></path>
                      </svg>&lt;
                      <!-- <div><img
                          src="@/assets/img/9b0b621ea271ac43a902cd4c2c5f1bad.jpg"></div> -->
                    </button>
                    <ul class="f-dots slick-dots" style="display: block;" role="tablist">
                      <li role="presentation" v-for="(item, index) in BannerList" :key="index"
                        :class="{ 'slick-active': index === currentIndex1 }" @click="goToSlide(index)">
                        <button type="button" role="tab" id="slick-slide-control00" tabindex="-1"></button>
                      </li>
                    </ul>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <!-- 服务与解决方案标题 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_62412476" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_62412476 clearfix">



              <div class="BodyCenter BodyCenter62412476 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer62412476" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62412476_1"
                      class="ModuleContainer SubContainer ModuleGridItem col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1200" id="module_62412477"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleImageTextGiant layout-101 layout-color-red module_62412477 clearfix"
                          style="transform: translate3d(0px, 0px, 0px);">



                          <div class="BodyCenter BodyCenter62412477 clearfix">

                            <div class="imageTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              autohide="0" hideheight="150" hidewidth="760">

                              <div class="ModuleImageTextGiantContent ModuleImageTextContent">

                                <p style="text-align: center; line-height: 2em;"><span
                                    style="font-family:Microsoft YaHei;">
                                    <h1 style="color: #000000; font-family: 'NotoSansHans-Medium';">服务与解决方案</h1>
                                  </span>

                                </p>



                                <p style="text-align: center; line-height: 2.5em;">


                                  <span
                                    style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">

                                  </span>

                                </p>



                                <p style="text-align: center; line-height: 2.5em;">

                                  <span>

                                    <span style="color:#000000; font-size: 16px;">Services and Solutions</span>

                                  </span>

                                </p>

                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>



          </div>

          <!-- 服务与解决方案列表 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_63083124" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_63083124 clearfix">



              <div class="BodyCenter BodyCenter63083124 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer63083124" gridswidthmode="0">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub63083124_1"
                      class="fuwuslide pc_height ModuleContainer SubContainer ModuleGridItem col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1358" id="module_63087856"
                        style=" visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                        <div class=" ModuleNewsListGiant layout-101 layout-color-orange">

                          <!--所有的文章项目应该包含在一个DIV里，并且这个DIV要有一个news-container的css类名-->

                          <swiper :options="fuwuSwiper" v-if="isWideScreen" class=" news-container row">
                            <!--for循环读取文章数据-->
                            <swiper-slide v-for="(item2,index2) in serviceList2" :key="index2"
                              class="yinying col-lg-3 col-md-6 col-sm-6 col-xs-12">

                              <div v-for="(item,index) in item2" :key="index" class="box-border ">

                                <div class="news-img">

                                  <router-link
                                    :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}">

                                    <img :src="item.picture"
                                      data-src="@/assets/img/4d0c68a6f85b9a92e5ca71292d537e8c.jpg" delay-inited="true"
                                      style="background: none;">

                                  </router-link>

                                </div>

                                <router-link
                                  :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                  class="news-title"><b>{{item.title}}</b></router-link>

                                <router-link
                                  :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                  class="news-desc">{{item.description}}</router-link>

                                <div style="width:100px;margin:0 auto;" class="bb">

                                  <span>

                                    <router-link
                                      :to="{path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                      class="news-detailbtn btn btn-sm btn-primary">详细内容&gt;&gt;</router-link>

                                  </span>

                                </div>

                              </div>

                            </swiper-slide>
                          </swiper>

                          <div v-else class="news-container row">
                            <!--for循环读取文章数据-->
                            <div v-for="(item,index) in serviceList" :key="index"
                              class="col-lg-3 col-md-6 col-sm-6 col-xs-12">

                              <div class="box-border ">

                                <div class="news-img">

                                  <router-link
                                    :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}">

                                    <img :src="item.picture"
                                      data-src="@/assets/img/4d0c68a6f85b9a92e5ca71292d537e8c.jpg" delay-inited="true"
                                      style="background: none;">

                                  </router-link>

                                </div>

                                <router-link
                                  :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                  class="news-title"><b>{{item.title}}</b></router-link>

                                <router-link
                                  :to="{ path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                  class="news-desc">{{item.description}}</router-link>

                                <div style="width:100px;margin:0 auto;" class="bb">

                                  <span>

                                    <router-link
                                      :to="{path: '/service', query: {category_id:item.id, category_title:item.title }}"
                                      class="news-detailbtn btn btn-sm btn-primary">详细内容&gt;&gt;</router-link>

                                  </span>

                                </div>

                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      <ul class="xiaoshi xdot"
                        style="position:absolute; width: auto; height: 5px; bottom:0px;left: 50%; transform: translateX(-50%);">
                        <li class="" v-for="(item,index) in serviceList2" :key="index">
                        </li>
                      </ul>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>

          </div>



          <!-- 集团简介 -->

          <div class="ModuleItem wow fadeInUp StaticModule" wo="1358" id="module_62412483" data-wow-duration="1s"
            style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp; height: auto;">

            <div class="ModuleGridCustomGiant layout-101 layout-color- module_62412483 clearfix">



              <div class="BodyCenter BodyCenter62412483 clearfix">



                <div class="ModuleGridContainer  ModuleGridContainer62412483" gridswidthmode="1">

                  <div class="row ModuleGridCustomContainer ModuleSubContainer grid-col-1">

                    <div class="gridBgVideo noBgVideo"><video src="" class="bgVideo" autoplay="" loop="loop"></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62412483_1" class="ModuleSubGridCustomBox ModuleContainer SubContainer "
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem  StaticModule  " wo="587" id="module_62412485">

                        <div class="ModuleImageGiant layout-101 layout-color- module_62412485 clearfix">



                          <div class="BodyCenter BodyCenter62412485 clearfix"> <img class="image-animation"
                              src="@/assets/img/20210416101336e2d84f.jpg"
                              data-src="/public/img/20210416101336e2d84f.jpg"
                              url="/comdata/98325/202104/20210416101336e2d84f.jpg" alt="图片展示" delay-inited="true"
                              style="background: none;">

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="SubPadding SubPadding1"></div>

                    <div id="Sub62412483_2" class="ModuleSubGridCustomBox ModuleContainer SubContainer "
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem  StaticModule  " wo="771" id="module_66224828">

                        <div class="ModuleImageTextGiant layout-101 layout-color-red module_66224828 clearfix"
                          style="transform: translate3d(0px, 0px, 0px);">



                          <div class="BodyCenter BodyCenter66224828 clearfix">

                            <div class="imageTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              autohide="0" hideheight="150" hidewidth="760">

                              <div class="ModuleImageTextGiantContent ModuleImageTextContent">

                                <p style="line-height: 2em;">
                                  <strong>
                                    <span style="font-size:16px; margin: 10px 0;">
                                      <h1>集团简介</h1>
                                    </span>
                                  </strong>
                                </p>
                                <p style=" line-height: 2.5em; padding: 10px 0;">
                                  <span
                                    style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">
                                  </span>
                                </p>



                                <div v-html="($sanitize(siteList.company_description))">

                                </div>



                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>



          </div>



          <!-- 数值 -->

          <div class="ModuleItem wow slideInLeft StaticModule" wo="1358" id="module_62412492" data-wow-duration="1.5s"
            style="visibility: visible; animation-duration: 1.5s; animation-name: slideInLeft; height: auto;">

            <div class="ModuleGridCustomGiant layout-101 layout-color- module_62412492 clearfix">



              <div class="BodyCenter BodyCenter62412492 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer62412492" gridswidthmode="1">

                  <div class="row ModuleGridCustomContainer ModuleSubContainer grid-col-1">

                    <div class="gridBgVideo noBgVideo"><video src="" class="bgVideo" autoplay="" loop="loop"></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62412492_1" class="ModuleSubGridCustomBox ModuleContainer SubContainer "
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem  StaticModule  mhidden-xs" wo="1103" id="module_62412491">

                        <div class="ModuleDigitalIncreaseGiant layout-102 layout-color- module_62412491 clearfix">



                          <div class="BodyCenter BodyCenter62412491 clearfix">

                            <div class="digitalIncreaseGiant-Container clearfix">

                              <div class="digital-row">

                                <div class="digital-item digital-line col-xs-2 col-sm-2 col-md-4 col-lg-4">

                                  <div class="ico ico-4"><img src="@/assets/img/20200522181036713b95.jpg"
                                      data-src="/public/img/20200522181036713b95.jpg" width="60" height="60"
                                      delay-inited="true" style="background: none;"></div>

                                  <p class="digital-num-item">

                                    <span class="digital-num" data-separator="1" data-to="10"
                                      data-speed="2">{{siteList.branch_num}}</span>

                                    <sup class="digital-unit">+</sup>

                                  </p>

                                  <p class="digital-title" title="分支机构"
                                    style="height: 21px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;">
                                    分支机构</p>

                                </div>

                                <div class="digital-item digital-line col-xs-2 col-sm-2 col-md-4 col-lg-4">

                                  <div class="ico ico-4"><img src="@/assets/img/202005221810369e032b.jpg"
                                      data-src="/public/img/202005221810369e032b.jpg" width="60" height="60"
                                      delay-inited="true" style="background: none;"></div>

                                  <p class="digital-num-item">

                                    <span class="digital-num" data-separator="1" data-to="200"
                                      data-speed="2">{{siteList.team_num}}</span>

                                    <sup class="digital-unit">+</sup>

                                  </p>

                                  <p class="digital-title" title="服务团队"
                                    style="height: 21px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;">
                                    服务团队</p>

                                </div>

                                <div class="digital-item digital-line col-xs-2 col-sm-2 col-md-4 col-lg-4">

                                  <div class="ico ico-4"><img src="@/assets/img/20200522181036ea1aa4.jpg"
                                      data-src="/public/img/20200522181036ea1aa4.jpg" width="60" height="60"
                                      delay-inited="true" style="background: none;"></div>

                                  <p class="digital-num-item">

                                    <span class="digital-num" data-separator="1" data-to="20000"
                                      data-speed="2">{{employee_num}}</span>

                                    <sup class="digital-unit">+</sup>

                                  </p>

                                  <p class="digital-title" title="企业客户"
                                    style="height: 21px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;">
                                    企业客户</p>

                                </div>

                                <div class="digital-item digital-line col-xs-2 col-sm-2 col-md-4 col-lg-4">

                                  <div class="ico ico-4"><img src="@/assets/img/20200522181036460996.jpg"
                                      data-src="/public/img/20200522181036460996.jpg" width="60" height="60"
                                      delay-inited="true" style="background: none;"></div>

                                  <p class="digital-num-item">

                                    <span class="digital-num" data-separator="1" data-to="50000"
                                      data-speed="2">{{member_num}}</span>

                                    <sup class="digital-unit">+</sup>

                                  </p>

                                  <p class="digital-title" title=" 个人会员"
                                    style="height: 21px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;">
                                    个人会员</p>

                                </div>

                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="SubPadding SubPadding1"></div>

                    <!-- <div id="Sub62412492_2" class="ModuleSubGridCustomBox ModuleContainer SubContainer "
                      positiontype="2" ismodulecontainer="true"></div> -->

                  </div>

                </div>

              </div>

            </div>



          </div>



          <!-- 简介轮播 -->

          <div class="ModuleItem wow fadeInUp StaticModule" wo="1358" id="module_62412495" data-wow-duration="1.5s"
            style="visibility: visible; animation-duration: 1.5s; animation-name: fadeInUp; height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_62412495 clearfix">



              <div class="BodyCenter BodyCenter62412495 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer62412495" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo"><video src="" class="bgVideo" autoplay="" loop="loop"></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62412495_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule  mhidden-xs" wo="1200" id="module_62412496"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleSlideV2Giant layout-108 layout-color-black module_62412496 clearfix">



                          <div v-if="environment.length>0" class="BodyCenter BodyCenter62412496 clearfix">
                            <div
                              class="s-slide slider-layout-box slider-layout-108 slick-initialized slick-slider slick-dotted">
                              <button class="s-prev slick-prev slick-arrow" aria-label="Previous"
                                type="button">Previous</button>
                              <div class="slick-list draggable">
                                <swiper :options="homeSwiper2" ref="mySwiper2">
                                  <swiper-slide v-for="(item, index) in environment" :key="item.index">
                                    <div>
                                      <a href="javascript:void(0)">
                                        <div class="slide-box">
                                          <img class="swiperImg" :src="item.picture" alt="团队" title="团队">
                                        </div>
                                      </a>
                                    </div>
                                  </swiper-slide>
                                </swiper>
                              </div>
                              <button class="s-next slick-next slick-arrow" aria-label="Next"
                                type="button">Next</button>
                              <ul class="s-dots slick-dots change-dots" role="tablist">
                                <span v-for="(item, index) in environment" :key="item.index"
                                  :class="{'slick-active': index === currentIndex2}">
                                  <button type="button" role="tab"></button>
                                </span>
                              </ul>
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>



          <!-- 合作标题 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_62420022" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_62420022 clearfix">



              <div class="BodyCenter BodyCenter62420022 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer62420022" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62420022_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1200" id="module_62420034"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleImageTextGiant layout-101 layout-color-red module_62420034 clearfix"
                          style="transform: translate3d(0px, 0px, 0px);">



                          <div class="BodyCenter BodyCenter62420034 clearfix">

                            <div class="imageTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              autohide="0" hideheight="150" hidewidth="760">

                              <div class="ModuleImageTextGiantContent ModuleImageTextContent">

                                <p style="text-align: center; line-height: 2em;"><span
                                    style="font-family:Microsoft YaHei;">
                                    <h1 style="color: #000000; font-family: 'NotoSansHans-Medium';">合作客户</h1>
                                  </span>

                                </p>



                                <p style="text-align: center; line-height: 2.5em;">
                                  <span
                                    style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">
                                  </span>
                                </p>

                                <p style="text-align: center; line-height: 2.5em;"><span><span
                                      style="color:#000000;">Cooperation

                                      Client</span></span></p>

                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>

          </div>



          <!-- 合作客户 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_63747722" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_63747722 clearfix">



              <div class="BodyCenter BodyCenter63747722 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer63747722" gridswidthmode="0">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo"><video src="" class="bgVideo" autoplay="" loop="loop"></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub63747722_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1358" id="module_63747725"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleSiteGalleryV2Giant layout-112 layout-color- module_63747725 clearfix">

                          <div class="BodyCenter BodyCenter63747725 clearfix">

                            <div class="Photo_box">

                              <!-- Swiper -->

                              <div v-if="partner.length>0"
                                class="swiper-container gallery-top swiper-container-horizontal">

                                <swiper class="" :options="homeSwiper3" ref="mySwiper3"
                                  @slideChange="onSlideChange('mySwiper3')">
                                  <swiper-slide v-for="(item,index) in partner" :key="index"
                                    class=" col-xs-2 col-sm-4 col-md-4 col-lg-4" data-swiper-slide-index="10">

                                    <a href="https://wq01.com/decai.com" target="_blank">

                                      <div class="gallery-list gallery-giant">

                                        <div class="head">

                                          <img v-lazy="item.picture" alt="" :title="item.pic" delay-inited="true"
                                            style="">

                                          <div class="dummy"></div>

                                        </div>

                                        <div class="content">

                                        </div>

                                      </div>

                                    </a>

                                  </swiper-slide>

                                </swiper>

                              </div>



                              <!-- 翻页 -->

                              <div class="t-next swiper-next"><svg t="1731474861806" class="icon"
                                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8651"
                                  width="32" height="32">
                                  <path
                                    d="M761.055557 532.128047c0.512619-0.992555 1.343475-1.823411 1.792447-2.848649 8.800538-18.304636 5.919204-40.703346-9.664077-55.424808L399.935923 139.743798c-19.264507-18.208305-49.631179-17.344765-67.872168 1.888778-18.208305 19.264507-17.375729 49.631179 1.888778 67.872168l316.960409 299.839269L335.199677 813.631716c-19.071845 18.399247-19.648112 48.767639-1.247144 67.872168 9.407768 9.791372 21.984142 14.688778 34.560516 14.688778 12.000108 0 24.000215-4.479398 33.311652-13.439914l350.048434-337.375729c0.672598-0.672598 0.927187-1.599785 1.599785-2.303346 0.512619-0.479935 1.056202-0.832576 1.567101-1.343475C757.759656 538.879828 759.199462 535.391265 761.055557 532.128047z"
                                    fill="#575B66" p-id="8652"></path>
                                </svg></div>

                              <div class="t-prev swiper-prev"><svg t="1731474807538" class="icon"
                                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7576"
                                  width="32" height="32">
                                  <path
                                    d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z"
                                    fill="#575B66" p-id="7577"></path>
                                </svg></div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>



          </div>



          <!-- 新闻标题 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_63112200" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_63112200 clearfix">



              <div class="BodyCenter BodyCenter63112200 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer63112200" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub63112200_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1200" id="module_63124987"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModulePlainTextGiant layout-101 layout-color- module_63124987 clearfix">



                          <div class="BodyCenter BodyCenter63124987 clearfix">

                            <div class="plainTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              hidewidth="760">

                              <div class="ModulePlainTextGiantContent ModuleImageTextContent">


                                <p style="text-align: center; line-height: 2em;"><span
                                    style="font-family:Microsoft YaHei;">
                                    <h1 style="position: relative; color: #000000; font-family: 'NotoSansHans-Medium';">新闻中心
                                      <div class="ModuleItem wow fadeInUp StaticModule" wo="600" id="module_71288808"
                                        data-wow-duration="2.5s"
                                        style="position: absolute; visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">

                                        <div style="position: absolute; right: 0; bottom: 0;" class="ModuleButtonGiant layout-102 layout-color-black module_71288808 clearfix">
                                          <div v-if="newscategory.length>0"
                                            class="BodyCenter BodyCenter71288808 clearfix">
                                            <router-link
                                              :to="{path:'/news',query:{category_id:newscategory[0].id,category_title:newscategory[0].title}}"
                                              target="_self" class="moduleButton hvr-sweep-to-right">

                                              查看更多>>

                                            </router-link>

                                          </div>

                                        </div>



                                      </div>
                                    </h1>

                                  </span>

                                </p>



                                <p style="text-align: center; line-height: 2em;">
                                  <span
                                    style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">
                                  </span>
                                </p>



                                <p style="text-align: center; line-height: 2.5em;">

                                  <span>

                                    <font color="#000000">News Center</font>

                                  </span>

                                </p>



                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1200" id="module_70643365"
                        data-wow-duration="2.5s"
                        style="visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">

                        <div class="ModulePlainTextGiant layout-101 layout-color- module_70643365 clearfix">

                          <div class="BodyCenter BodyCenter70643365 clearfix">

                            <div class="plainTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              hidewidth="760">

                              <div class="ModulePlainTextGiantContent ModuleImageTextContent">

                                <p style="text-align: center;">

                                  <span style="font-size:16px;">

                                    <span v-if="newscategory.length>0" style="font-family:Microsoft YaHei;">


                                      <router-link :style="{'border-bottom':index===0 ? '3px solid #3696FC' : 'none'}"
                                        style="display: inline-block; width:200px;padding-bottom: 10px; font-size: 22px;"
                                        v-for="(item,index) in newscategory" :key="index"
                                        :to="{path:'/news',query:{category_id:item.id,category_title:item.title}}">

                                        <span
                                          :style="{'color':index===0 ?'#3696FC':'#333333','padding-bottom':index===0?'1px':'0px'}">{{item.title}}</span>

                                      </router-link>


                                    </span>

                                  </span>

                                </p>

                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>

          </div>



          <!-- 新闻 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_70643565" style="height: auto;">

            <div class="ModuleGridGiant layout-101 layout-color- module_70643565 clearfix">



              <div class="BodyCenter BodyCenter70643565 clearfix" style="border-top: 1px solid #F3F5F7;">

                <div class="ModuleGridContainer  ModuleGridContainer70643565" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub70643565_1"
                      class="ModuleContainer SubContainer ModuleGridItem col-xs-12 col-sm-6 col-md-6 col-lg-6"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule  mhidden-xs" wo="600" id="module_63124993"
                        data-wow-duration="2.5s"
                        style="visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">

                        <div class="ModuleNewsListGiant layout-101 layout-color-orange module_63124993 clearfix">



                          <div class="BodyCenter BodyCenter63124993 clearfix">

                            <div v-if="topNews.length>0" class="swiper-container swiper-container-horizontal"
                              layout="101">

                              <swiper class="news-container swiper-wrapper" :options="homeSwiper4" ref="mySwiper4">

                                <!--ITEMSTART-->

                                <swiper-slide v-for="(item,index) in topNews" :key="index"
                                  class="news-item swiper-slide col-xs-1 col-sm- col-md- col-lg-1 swiper-slide-active"
                                  style="width: 555px;">

                                  <router-link
                                    :to="{path:'/newsdetail',query:{id:item.id,category_id:item.category_id}}"
                                    class="clearfix">

                                    <div class="news-img">

                                      <img :src="item.picture" alt="法税私董会暨资源对接会②" title="法税私董会暨资源对接会②" class="t-img"
                                        delay-inited="true" style="background: none;">

                                      <div class="dummy"></div>

                                    </div>

                                    <div class="news-tit" dir="ltr">

                                      <p class="news-title  layout-101-news news-title-oneline ">

                                        <i class="staFun">顶</i>
                                        <span class="laout-101-title">{{item.title}}</span>

                                      </p>

                                      <time>{{item.created_at[0]}}-{{item.created_at[1]}}-{{item.created_at[2]}}</time>

                                      <p class="news-desc  layout-101-desc " title="

                                                                         

                                                                    "
                                        style="height: 21px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 1; display: -webkit-box; -webkit-box-orient: vertical;">

                                        <span class="laout-103-news-desc clamp-ellipsis">&nbsp;</span>

                                      </p>

                                      <div class="label-box" style="height: 0px;">

                                      </div>

                                    </div>

                                  </router-link>

                                </swiper-slide>
                                <!--ITEMEND-->

                              </swiper>

                              <div class="fo-prev swiper-butto-prev swiper-button-white iconfont icon-icon-left">
                                <svg t="1731475915137" viewBox="0 0 1024 1024" version="1.1"
                                  xmlns="http://www.w3.org/2000/svg" p-id="2280" width="16" height="16" class="icon">
                                  <path
                                    d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z"
                                    fill="#ffffff" p-id="2281"></path>
                                </svg>
                              </div>

                              <div
                                class="fo-next swiper-butto-next swiper-button-white iconfont icon-icon-left swiper-button-disabled">
                                <svg t="1731475915137" viewBox="0 0 1024 1024" version="1.1"
                                  xmlns="http://www.w3.org/2000/svg" p-id="2280" width="16" height="16" class="icon">
                                  <path
                                    d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z"
                                    fill="#ffffff" p-id="2281"></path>
                                </svg>
                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-xs"></div>



                    <!--新闻列表-PC-->

                    <div id="Sub70643565_2"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-6 col-md-6 col-lg-6"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="600" id="module_63125943"
                        data-wow-duration="2.5s"
                        style="visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">

                        <div class="ModuleNewsListGiant layout-120 layout-color-blue module_63125943 clearfix">

                          <div class="BodyCenter BodyCenter63125943 clearfix">

                            <div>

                              <ul class="news-container clearfix">


                                <!--ITEMSTART-->

                                <li v-for="(item,index) in newsList.slice(0,6)" class="news-item fullcontainergrid">

                                  <router-link
                                    :to="{path:'/newsdetail',query:{id:item.id,category_id:item.category_id}}"
                                    target="_blank">

                                    <div class="news-tit ">

                                      <div class="news-span1">

                                        <div class="news-time">

                                          <div class="news-time-date">

                                            {{item.created_at[1]}}-{{item.created_at[2]}}
                                          </div>

                                          <div class="news-time-year">{{item.created_at[0]}}</div>

                                        </div>

                                      </div>

                                      <div class="news-span2">

                                        <div class="news-action ">

                                          <p class="news-title ttop">

                                            <span class="0 recommend-title  news-title-oneline ">


                                              <span>{{item.title}}</span>

                                            </span>

                                          </p>

                                          <p class="news-desc"
                                            style="-webkit-line-clamp: 1; height: 24px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical;"
                                            title=" 

                                                                            ">&nbsp;

                                          </p>

                                          <div class="label-box" style="height: 0px;">

                                          </div>

                                        </div>

                                      </div>

                                    </div>

                                  </router-link>

                                </li>
                                <!--ITEMEND-->
                              </ul>

                            </div>

                          </div>

                        </div>



                      </div>

                      <!-- <div class="ModuleItem wow fadeInUp StaticModule" wo="600" id="module_71288808"
                        data-wow-duration="2.5s"
                        style="visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">

                        <div class="ModuleButtonGiant layout-102 layout-color-black module_71288808 clearfix">



                          <div v-if="newscategory.length>0" class="BodyCenter BodyCenter71288808 clearfix">
                            <router-link
                              :to="{path:'/news',query:{category_id:newscategory[0].id,category_title:newscategory[0].title}}"
                              target="_self" class="moduleButton hvr-sweep-to-right">

                              查看更多

                            </router-link>

                          </div>

                        </div>



                      </div> -->

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>



          </div>



          <div class="ModuleItem  StaticModule  " wo="1358" id="module_62420022">

            <div class="ModuleGridGiant layout-101 layout-color- module_62420022 clearfix">



              <div class="BodyCenter BodyCenter62420022 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer62420022" gridswidthmode="2">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo">

                      <video src="" class="bgVideo" autoplay="" loop=""></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub62420022_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1200" id="module_62420034"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleImageTextGiant layout-101 layout-color-red module_62420034 clearfix"
                          style="transform: translate3d(0px, 0px, 0px);">



                          <div class="BodyCenter BodyCenter62420034 clearfix">

                            <div class="imageTextGiant-Container imageTextContainer clearfix" hasresponsive="1"
                              autohide="0" hideheight="150" hidewidth="760">

                              <div class="ModuleImageTextGiantContent ModuleImageTextContent">


                                <p style="text-align: center; line-height: 2em;"><span
                                    style="font-family:Microsoft YaHei;">
                                    <h1 style="color: #000000; font-family: 'NotoSansHans-Medium';">旗下品牌</h1>
                                  </span>
                                </p>

                                <p style="text-align: center; line-height: 2.5em;">
                                  <span
                                    style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">
                                  </span>
                                </p>



                                <p style="text-align: center; line-height: 2.5em;"><span><span
                                      style="color:#000000;">Brand name</span></span>
                                </p>



                              </div>

                            </div>

                          </div>

                        </div>



                      </div>

                    </div>

                    <div class="clearfix visible-lg"></div>

                    <div class="clearfix visible-xs"></div>

                  </div>

                </div>

              </div>

            </div>

          </div>

          <!-- 合作客户 -->

          <div class="ModuleItem  StaticModule  " wo="1358" id="module_63747722">

            <div class="ModuleGridGiant layout-101 layout-color- module_63747722 clearfix">



              <div class="BodyCenter BodyCenter63747722 clearfix">

                <div class="ModuleGridContainer  ModuleGridContainer63747722" gridswidthmode="0">

                  <div class="row ModuleSubContainer">

                    <div class="gridBgVideo noBgVideo"><video src="" class="bgVideo" autoplay="" loop="loop"></video>

                      <div class="bgVideoMask"></div>

                    </div>

                    <div id="Sub63747722_1"
                      class="ModuleContainer SubContainer ModuleGridItem     col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      positiontype="2" ismodulecontainer="true">

                      <div class="ModuleItem wow fadeInUp StaticModule" wo="1358" id="module_63747725"
                        data-wow-duration="1s"
                        style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">

                        <div class="ModuleSiteGalleryV2Giant layout-112 layout-color- module_63747725 clearfix">

                          <div class="BodyCenter BodyCenter63747725 clearfix">

                            <div class="Photo_box">

                              <!-- Swiper -->

                              <div v-if="brandList.length>0"
                                class="swiper-container gallery-top swiper-container-horizontal">

                                <swiper class="" :options="homeSwiper5" ref="mySwiper5"
                                  @slideChange="onSlideChange('mySwiper5')">
                                  <swiper-slide v-for="(item,index) in brandList" :key="index"
                                    class=" col-xs-2 col-sm-4 col-md-4 col-lg-4" data-swiper-slide-index="10">

                                    <a href="https://wq01.com/decai.com" target="_blank">

                                      <div class="gallery-list gallery-giant">

                                        <div class="head">

                                          <img :src="item.pic" alt="" :title="item.pic" delay-inited="true" style="">

                                          <div class="dummy"></div>

                                        </div>

                                        <div class="content">

                                        </div>

                                      </div>

                                    </a>

                                  </swiper-slide>

                                </swiper>

                              </div>
                              <!-- 翻页 -->

                              <div class="t-next swiper-next"><svg t="1731474861806" class="icon"
                                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8651"
                                  width="32" height="32">
                                  <path
                                    d="M761.055557 532.128047c0.512619-0.992555 1.343475-1.823411 1.792447-2.848649 8.800538-18.304636 5.919204-40.703346-9.664077-55.424808L399.935923 139.743798c-19.264507-18.208305-49.631179-17.344765-67.872168 1.888778-18.208305 19.264507-17.375729 49.631179 1.888778 67.872168l316.960409 299.839269L335.199677 813.631716c-19.071845 18.399247-19.648112 48.767639-1.247144 67.872168 9.407768 9.791372 21.984142 14.688778 34.560516 14.688778 12.000108 0 24.000215-4.479398 33.311652-13.439914l350.048434-337.375729c0.672598-0.672598 0.927187-1.599785 1.599785-2.303346 0.512619-0.479935 1.056202-0.832576 1.567101-1.343475C757.759656 538.879828 759.199462 535.391265 761.055557 532.128047z"
                                    fill="#575B66" p-id="8652"></path>
                                </svg></div>
                              <div class="t-prev swiper-prev"><svg t="1731474807538" class="icon"
                                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7576"
                                  width="32" height="32">
                                  <path
                                    d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z"
                                    fill="#575B66" p-id="7577"></path>
                                </svg></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix visible-lg"></div>
                    <div class="clearfix visible-xs"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="link">
            <div class="title">

              <p style="text-align: center; line-height: 2em;"><span style="font-family:Microsoft YaHei;">
                  <h1 style="color: #000000; font-family: 'NotoSansHans-Medium';">友情链接</h1>
                </span>

              </p>
              <p style="text-align: center; line-height: 2em;">
                <span
                  style="display: inline-block; text-align: center; width: 45px;height: 5px; background-color: #3696FC;">
                </span>
              </p>
              <p style="text-align: center; line-height: 2.5em;">
                <span>
                  <font color="#000000">Link Exchange</font>
                </span>
              </p>
            </div>
            <ul>
              <li v-for="(item,index) in linkList" :key="index">
                <a :href="item.link" target="_blank">{{item.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        currentIndex1: 0,
        currentIndex2: 0,
        currentIndex3: 0,
        currentIndex4: 0,
        siteList: [],
        isLoading: true,
        serviceList: [],
        serviceList2: [],
        environment: [],
        partner: [],
        newsList: [],
        newscategory: [],
        topNews: [],
        brandList: [],
        linkList: [],
        BannerList: [],
        isWideScreen: false,
        fuwuSwiper: {

          pagination: {
            el: ".xdot",
            clickable: true
          },
        },
        homeSwiper: {
          effect: 'fade',
          fadeEffect: {
            crossFade: true // 启用交叉渐变效果
          },
          speed: 2000,
          loop: true,
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户交互后继续自动播放
          },
          navigation: {
            nextEl: '.f-next',
            prevEl: '.f-prev',
          },
          pagination: {
            el: ".f-dots",
            clickable: true
          },
        },
        homeSwiper2: {
          slidesPerView: 1.95,
          centeredSlides: true,
          spaceBetween: 10,
          loop: true,
          loopedSlides: 4,
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户交互后继续自动播放
          },
          speed: 2000,
          navigation: {
            nextEl: '.s-next',
            prevEl: '.s-prev',
          },
          pagination: {
            el: ".s-dots",
            clickable: true
          },
          observer: true, // 启用观察者模式,检测DOM变化并更新Swiper实例。
        },
        homeSwiper3: {
          slidesPerView: 4,
          spaceBetween: 40,
          loop: true,
          navigation: {
            nextEl: '.t-next',
            prevEl: '.t-prev',
          },
          speed: 2000,
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户交互后继续自动播放
          },
        },
        homeSwiper4: {
          loop: true,
          speed: 1500,
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户交互后继续自动播放
          },
          navigation: {
            nextEl: '.fo-prev',
            prevEl: '.fo-next',
          },
          pagination: {
            el: "",
            clickable: true
          }
        },
        homeSwiper5: {
          slidesPerView: 4,
          spaceBetween: 40,
          loop: true,
          navigation: {
            nextEl: '.t-next',
            prevEl: '.t-prev',
          },
          speed: 2000,
          autoplay: {
            delay: 3000, // 自动播放间隔时间
            disableOnInteraction: false // 用户交互后继续自动播放
          },
        },
      }
    },
    computed: {
      member_num() {
        if (!this.isLoading) {
          return this.siteList.train_member_num.toLocaleString()
        }
      },
      employee_num() {
        if (!this.isLoading) {
          return this.siteList.employee_num.toLocaleString()
        }
      }
    },
    created() {
      this.getBanner()
      this.getService()
      this.getEnvironment()
      this.getPartner()
      this.getNewsCategory()
      this.getNews()
      this.getBrand()
      this.getLink()
      this.getSite()
      document.title = "伟企集团 - 数智化一站式企服平台"
    },
    mounted() {
      this.handleResize();
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.isWideScreen = window.innerWidth >= 768;
      },
      goToSlide(index, swiperRef = 'mySwiper1') {
        this.$refs[swiperRef].swiper.slideTo(index, 300, true); // 300是过渡时间，true表示回调函数
      },
      onSlideChange(swiperRef) {
        if (swiperRef === 'mySwiper1') {
          this.currentIndex1 = this.$refs[swiperRef].swiper.realIndex;
        } else if (swiperRef === 'mySwiper2') {
          this.$nextTick(() => {
            this.$refs[swiperRef].swiper.update();
          });
        } else if (swiperRef === 'mySwiper3') {
          this.currentIndex3 = this.$refs[swiperRef].swiper.realIndex;
        } else if (swiperRef === 'mySwiper4') {
          this.currentIndex4 = this.$refs[swiperRef].swiper.realIndex;
        }
      },
      async getBanner() {
        let res = await this.$request.post('banner/getAll')
        if (res.data.code === 0) {
          this.BannerList = res.data.data
        }
      },
      async getService() {
        let res = await this.$request.post('service/getAll')
        if (res.data.code === 0) {
          this.serviceList = res.data.data
          const groupSize = 4;
          this.serviceList2 = this.serviceList.reduce((acc, item, index) => {
            if (index % groupSize === 0) {
              acc.push([item]);
            } else {
              acc[acc.length - 1].push(item);
            }
            return acc;
          }, [])
        }
      },
      async getEnvironment() {
        let res = await this.$request.post('Environmentimage/getAll')
        if (res.data.code === 0) {
          this.environment = res.data.data
        }
      },
      async getPartner() {
        let res = await this.$request.post('partner/getAll')
        if (res.data.code === 0) {
          this.partner = res.data.data
        }
      },
      async getNewsCategory() {
        let res = await this.$request.post('newscategory/getAll')
        if (res.data.code === 0) {
          this.newscategory = res.data.data
        }
      },
      async getNews() {
        let res = await this.$request.post('news/getAll', {
          fil: 1
        })
        if (res.data.code === 0) {
          this.newsList = res.data.data
          this.newsList.forEach(item => {
            item.created_at = item.created_at.split(' ')[0]
            item.created_at = item.created_at.split('-')
          })
          this.topNews = this.newsList.filter(item => item.is_topping === '是')
        }
      },
      async getBrand() {
        let res = await this.$request.post('brand/getAll')
        if (res.data.code === 0) {
          this.brandList = res.data.data
        }
      },
      async getLink() {
        let res = await this.$request.post('link/getAll')
        if (res.data.code === 0) {
          this.linkList = res.data.data
        }
      },
      async getSite() {
        let res = await this.$request.post('seo/getSite')
        if (res.data.code === 0) {
          this.siteList = res.data.data[0]
          this.isLoading = false;
        }
      }
    },
  }
</script>
<style lang="scss">
  @media screen and (min-width:768px) {
    .pc_height {
      min-height: 575px !important;
    }

    .xiaoshi {
      display: flex;
    }

    .xiaoshi li {
      width: 50px;
      height: 4px;
      background-color: #418DCC;
      margin-right: 15px;
    }
  }

  @media screen and (max-width:768px) {
    .xiaoshi {
      display: none;
    }

  }

  .yinying {
    display: flex;
  }

  .yinying .box-border {
    width: 340px;
    margin: 0 15px 10px;
    box-shadow: 0px 0px 12px 0px rgba(132, 136, 141, 0.2);
  }

  .link ul {
    max-width: 1450px;
    /* padding-left: 15px;
    padding-right: 15px; */
    width: 100%;
    margin: 25px auto;
  }

  .link ul li:hover {
    color: #fff;
    background: #418dcc;
  }

  .link ul li:hover>a {
    color: #fff;
  }

  .link ul li {
    display: inline-block;
    padding: 5px 8px;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
  }

  /* .link ul li:nth-child(14n) {
    margin-right: 0;
    padding-right: 0;
  } */

  .fuwuslide .swiper-pagination-bullet {
    cursor: pointer;
    width: 50px;
    height: 4px;
    margin-right: 15px;
    border-radius: 0;
    background-color: #E0E0E0;
  }

  .fuwuslide .swiper-pagination-bullet-active {
    font-size: 18px;
    background: #418DCC;
  }

  .f-slide .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    display: inline-block;
    border-radius: 100%;
    background: white;
    opacity: 1;
  }

  .f-slide .swiper-pagination-bullet-active {
    font-size: 18px;
    background: #000;
  }

  .s-slide .swiper-pagination-bullet {
    cursor: pointer;
    width: 50px;
    height: 3px;
    margin-right: 15px;
    border-radius: 0;
    background: #E0E0E0;
  }

  .s-slide .swiper-pagination-bullet-active {
    font-size: 18px;
    background: #418DCC;
  }

  @media screen and (min-width:768px) {
    .PC_min {
      min-height: 50vh;
    }
  }
</style>