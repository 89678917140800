<template>
  <div id="app" >
    <Top />
    <div id="app-content">
      <router-view  />
    </div>
    <Footer :siteList="siteList"/>
    <RightNav :siteList="siteList"/>
  </div>
</template>
<script>
  import Top from '@/components/top'
  import Footer from '@/components/footer'
  import RightNav from '@/components/rightnav'
  export default {
    components: {
      Top,
      Footer,
      RightNav
    },
    data() {
      return {
         siteList:{}
      }
    },
    created() {
      this.getSite()
    },
    methods: {
     async getSite(){
      let res = await this.$request.post('seo/getSite')
      if (res.data.code===0) {
        this.siteList=res.data.data[0]
      }
    },
    },
  }
</script>
<style lang="scss">
 
  #app-content{
    min-height: 100vh !important;
  }
</style>